function sendEventData(eventData) {
    $.ajax({
        url: 'assets/ajax/localEventLogger.php',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify(eventData),
        success: function(response) {
            console.log('Event logged successfully:', response);
        },
        error: function(xhr, status, error) {
            console.error('Error logging event:', error);
        }
    });
}

$(document).ready(function() {

});
